import {
  FaChevronLeft,
  FaChevronRight, FaHome,
  FaRegClipboard, FaStore,
  FaSun, FaTrello,
  FaUsers, FaUsersCog
} from "react-icons/fa";
import {useContext, useState} from "react";
import {ApplicationContext} from "../../../../contexts/ApplicationContext";
import {Switch} from "@mui/material";
import {BsCalendar3, BsMoonStars} from "react-icons/bs";
import {GoScreenFull, GoScreenNormal} from "react-icons/go";
import {useFullscreen} from "rooks";
import LogoVB from "../../../../assets/img/logo-v-b.png";
import LogoHB from "../../../../assets/img/logo-h-b.png";
import LogoVW from "../../../../assets/img/logo-v-w.png";
import LogoHW from "../../../../assets/img/logo-h-w.png";
import {useLocation, useNavigate} from "react-router";
import {UserContext} from "../../../../contexts/UserContext";

const Menu = () => {
  const {appInfo, setAppInfo} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);
  const fullscreen = useFullscreen();
  const elem = document.documentElement;
  const [iconeFullScreen, setIconeFullScreen] = useState(<GoScreenFull size={20}/>);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setAppInfo({...appInfo, menuOpen: !appInfo.menuOpen});
  }

  const toggleTheme = () => {
    if (appInfo.theme === 'dark') {
      setAppInfo({...appInfo, theme: 'light'});
    } else {
      setAppInfo({...appInfo, theme: 'dark'});
    }
  }

  const toggleFullscreen = () => {
    // @ts-ignore
    fullscreen.toggle(elem);
    // @ts-ignore
    if (fullscreen.isFullscreen) {
      setIconeFullScreen(<GoScreenFull size={20}/>)
    } else {
      setIconeFullScreen(<GoScreenNormal size={20}/>)
    }
  }

  return (
    <div className={appInfo.menuOpen ? "menu open" : "menu"}>
      <div className={"toggle-menu"} onClick={toggleMenu}>
        {appInfo.menuOpen ? <FaChevronLeft size={15}/> : <FaChevronRight size={15}/>}
      </div>
      <div className={"menu-itens"}>
        <div className={"logo"}>
            {appInfo.theme === 'dark' ?
              <img src={appInfo.menuOpen ? LogoHW : LogoVW} alt={"Logo"}/>
              :
              <img src={appInfo.menuOpen ? LogoHB : LogoVB} alt={"Logo"}/>
            }
        </div>

        <div className={(location.pathname === "/" || location.pathname.includes('contato') || location.pathname.includes('sobre') || location.pathname.includes('politica') || location.pathname.includes("/perfil")) ? "menu-item ativo" : "menu-item"} onClick={() => {
          navigate('/')
        }}>
          <FaHome size={20}/>
          <span>Início</span>
        </div>

        {user.nivel.includes('admin') &&
          <div className={location.pathname.includes("/administrativo") ? "menu-item ativo" : "menu-item"} onClick={() => {
            navigate('/administrativo')
          }}>
            <FaUsersCog size={20}/>
            <span>Administrativo</span>
          </div>
        }
        
        {(user.nivel.includes('sadmin') || user.nivel.includes('planner')) &&
          <div className={location.pathname.includes("/agendamento") ? "menu-item ativo" : "menu-item"} onClick={() => {
            navigate('/agendamento')
          }}>
            <BsCalendar3 size={20}/>
            <span>Agendamento</span>
          </div>
        }

        {(user.nivel.includes('planner') || user.nivel.includes('atendimento') || user.nivel.includes('admin')) &&
          <div className={location.pathname.includes("/cliente") ? "menu-item ativo" : "menu-item"} onClick={() => {
            navigate('/cliente')
          }}>
            <FaStore size={20}/>
            <span>Clientes</span>
          </div>
        }
        
        {(user.nivel.includes('fotografia') || user.nivel.includes('designer') || user.nivel.includes('planner') || user.nivel.includes('atendimento') || user.nivel.includes('admin')) &&
          <div className={location.pathname.includes("/planejamento") ? "menu-item ativo" : "menu-item"}
               onClick={() => {
                 navigate('/planejamento')
               }}>
            <FaRegClipboard size={20}/>
            <span>Planejamentos</span>
          </div>
        }


        <div className={location.pathname.includes("/tarefa") ? "menu-item ativo" : "menu-item"} onClick={() => {
          navigate('/tarefa')
        }}>
          <FaTrello size={20}/>
          <span>Tarefas</span>
        </div>

        {user.nivel.includes('admin') &&
          <div className={location.pathname.includes("/usuario") ? "menu-item ativo" : "menu-item"} onClick={() => {
            navigate('/usuario')
          }}>
            <FaUsers size={20}/>
            <span>Usuários</span>
          </div>
        }
      </div>

      <div className={appInfo.menuOpen ? "box-theme" : "box-theme closed"}>
        <div className={"switcher"}>
          <BsMoonStars size={15}/>
          <Switch
            checked={appInfo.theme === 'dark'}
            onChange={toggleTheme}
            inputProps={{'aria-label': 'controlled'}}
          />
          <FaSun size={15}/>
        </div>
        <div className={"switcher-2"} onClick={() => {toggleTheme()}}>
          {appInfo.theme === 'dark' ? <FaSun size={15}/> : <BsMoonStars size={15}/>}
        </div>
        <div className={"div-fullscreen"} onClick={toggleFullscreen}>
          {iconeFullScreen}
        </div>
      </div>
    </div>
  );
}

export default Menu;