import {
  Autocomplete,
  Avatar, CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip
} from "@mui/material";
import {
  FaArrowLeft,
  FaCog,
  FaEdit,
  FaSignOutAlt
} from "react-icons/fa";
import {useContext, useRef, useState} from "react";
import {UserContext} from "../../../../contexts/UserContext";
import {useNavigate} from "react-router";
import useLogout from "../../../autenticacao/functions/useLogout";
import {ApplicationContext} from "../../../../contexts/ApplicationContext";
import Animacao from "../../../../assets/animations/warning-sign.json";
import Lottie from "react-lottie";
import useCheckVersion from "../../../base/functions/useCheckVersion";

const Topo = () => {
  const [avatarOpen, setAvatarOpen] = useState(false);
  const {user} = useContext(UserContext);
  const {appInfo} = useContext(ApplicationContext);
  const avatarRef = useRef<HTMLDivElement>(null);
  const logout = useLogout();
  const navigate = useNavigate();
  
  const versao = useCheckVersion(user.token);
  
  // const StyledBadge = styled(Badge)(({theme}) => ({
  //   '& .MuiBadge-badge': {
  //     backgroundColor: '#44b700',
  //     color: '#44b700',
  //     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  //     '&::after': {
  //       position: 'absolute',
  //       top: 0,
  //       left: 0,
  //       width: '100%',
  //       height: '100%',
  //       borderRadius: '50%',
  //       animation: 'ripple 1.2s infinite ease-in-out',
  //       border: '1px solid currentColor',
  //       content: '""',
  //     },
  //   },
  //   '@keyframes ripple': {
  //     '0%': {
  //       transform: 'scale(.8)',
  //       opacity: 1,
  //     },
  //     '100%': {
  //       transform: 'scale(2.4)',
  //       opacity: 0,
  //     },
  //   },
  // }));
  
  
  const itens = [
    {
      title: "titulo"
    }
  ];
  return (
    <div className={"topo dark"}>
      <div style={{
        width: "33%",
        display: "flex",
        alignItems: "center"
      }}>
        <Tooltip title={"Voltar"}>
          <IconButton onClick={() => {
            navigate(-1)
          }}>
            <FaArrowLeft size={"20px"} color={"primary"}/>
          </IconButton>
        </Tooltip>
        {appInfo.cabecalho}
      </div>
      <Autocomplete
        sx={{width: "33%", ml: "20px"}}
        id="free-solo-demo"
        freeSolo
        disabled
        size={"small"}
        options={itens.map((option: any) => option.title)}
        renderInput={(params) => <TextField {...params} label="Buscar"/>}
      />
      
      <div className={"topo-itens"} style={{width: "33%", justifyContent: "flex-end"}}>
        {/*<div className={"topo-item"}>*/}
        {/*  <FaRegComments size={20}/>*/}
        {/*</div>*/}
        
        {/*<div className={"topo-item"}>*/}
        {/*  <FaRegBell size={20}/>*/}
        {/*</div>*/}
        
        {!versao.data && versao.isValidating ?
          <CircularProgress/>
          :
          (versao.data && (parseInt(`${versao.data}`.replaceAll('.', '')) > parseInt(`${process.env.REACT_APP_VERSION}`.replaceAll('.', '')))) &&
          <div className={"topo-item"}>
            <Tooltip title={"A aplicação está desatualizada, pressione F5."}>
              <IconButton
                style={{maxWidth: "50px", maxHeight: "50px", backgroundColor: "#ff0"}}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: Animacao,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice"
                    },
                  }}
                  eventListeners={[{eventName: "destroy", callback: () => {}}]}
                />
              </IconButton>
            </Tooltip>
          </div>
        }
        
        <div className={"topo-item"} ref={avatarRef}>
          <Tooltip title="Configurações da conta">
            <IconButton
              onClick={() => {
                setAvatarOpen(true);
              }}
              size="small"
              sx={{ml: 2}}
              aria-controls={avatarOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={avatarOpen ? 'true' : undefined}
            >
              {/*<StyledBadge*/}
              {/*  overlap="circular"*/}
              {/*  anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}*/}
              {/*  variant="dot"*/}
              {/*>*/}
              <Avatar
                variant={"rounded"}
                alt={user.nome}
                src={user.avatar}
              />
              {/*</StyledBadge>*/}
            </IconButton>
          </Tooltip>
        </div>
        <Menu
          anchorEl={avatarRef.current}
          id="account-menu"
          open={avatarOpen}
          onClose={() => {
            setAvatarOpen(false)
          }}
          onClick={() => {
            setAvatarOpen(false)
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              position: "absolute",
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{horizontal: 'right', vertical: 'top'}}
          anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
        >
          <MenuItem onClick={() => {
            navigate('/perfil')
          }}>
            <Avatar/> Minha conta
          </MenuItem>
          <MenuItem onClick={() => {
            navigate('/usuario/read')
          }}>
            <ListItemIcon>
              <FaEdit/>
            </ListItemIcon>
            Editar conta
          </MenuItem>
          <Divider/>
          {user.nivel.includes('admin') &&
            <MenuItem onClick={() => {
              navigate('/configuracoes')
            }}>
              <ListItemIcon>
                <FaCog/>
              </ListItemIcon>
              Configurações
            </MenuItem>
          }
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <FaSignOutAlt/>
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default Topo;