import {useContext} from "react";
import {ApplicationContext} from "../../../../contexts/ApplicationContext";
import {createTheme, ThemeProvider} from "@mui/material";
import {ptBR} from "@mui/material/locale";
import {Outlet} from "react-router";
import {UserContext} from "../../../../contexts/UserContext";
import Rodape from "../Rodape";
import Topo from "../Topo";
import Menu from "../Menu";

const Layout = () => {
  const {appInfo} = useContext(ApplicationContext);
  const {user} = useContext(UserContext);

  const theme = createTheme(
    {
      palette: {
        mode: appInfo.theme === 'dark' ? "dark" : "light",
        primary: {
          main: '#a5b703',
          contrastText: '#e5e5e5'
        },
        secondary: {
          main: '#6a6b70',
          contrastText: '#e5e5e5'
        }
      }
    },
    ptBR
  )

  return (
    <ThemeProvider theme={theme}>
      <div className={appInfo.theme === 'dark' ? "layout dark" : "layout"}>
        {user.token !== '' ?
          <>
            <Menu/>
            <div className={appInfo.menuOpen ? "conteudo open" : "conteudo"}>
              <Topo/>
              <Outlet/>
              <Rodape/>
            </div>
          </>
          :
          <Outlet/>
        }
      </div>
    </ThemeProvider>
  );
}

export default Layout;