import useSWR from "swr";
import useAxiosBase from "../../../connections/functions/useAxiosBase";

export const useCheckVersion = (token: string) => {
  const requisicao = useAxiosBase(token);
  const fetcherGET = (url: string) => requisicao.get(url).then((res) => res.data);
  
  return useSWR(`/versao`, fetcherGET, {refreshInterval: 30000});
}

export default useCheckVersion;