import {FaRegCopyright} from "react-icons/fa";
import {Link} from "react-router-dom";

const Rodape = () => {
  return (
    <div className={"rodape"}>
      <span>
        <FaRegCopyright size={12}/>
        {new Date().getFullYear()} - SIG
        <a
          href={"https://itweb.net.br"}
          target={"_blank"}
          rel="noreferrer"
        >
          Itweb
        </a> - {process.env.REACT_APP_VERSION}
      </span>
      <span>
        <Link to={"/sobre"}>Sobre</Link>
        <Link to={'/politica'}>Política</Link>
        <Link to={'/contato'}>Contato</Link>
      </span>
    </div>
  );
}

export default Rodape;