import {useContext} from "react";
import {UserContext} from "../../../contexts/UserContext";
import {useNavigate} from "react-router";

const useLogout = () => {
  const {setUser} = useContext(UserContext);
  const navigate = useNavigate();

  return () => {
    setUser({uuid: '', nome: '', avatar: '', token: '', nivel: ''});
    localStorage.setItem('token', '');
    navigate('/login', {replace: true});
  };
}

export default useLogout;